import { AfterViewInit, Component, DoCheck, OnDestroy, OnInit, KeyValueDiffers, KeyValueDiffer } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CartService } from '../service/cart.service';
import { UserService } from '../service/user.service';
import { MatDialog } from '@angular/material/dialog';
import { MenuPromotion, PromotionVoucher, Store } from '../interface/menu.interface';
import { CartItem } from '../interface/cart.interface';
import { MenuService } from '../service/menu.service';
import { User } from '../interface/user.interface';
import { CommonService } from '../service/common.service';
import { OrderPickupDatetime, OrderToSubmit } from '../interface/order.interface';
import { PaymentOptionPopupComponent } from '../popup/payment-option-popup/payment-option-popup.component';
import { VoucherPopupComponent } from '../popup/voucher-popup/voucher-popup.component';
import { DatetimePopupComponent } from '../popup/datetime-popup/datetime-popup.component';
import { PaymentComponent } from '../payment/payment.component';
import { StorePopupComponent } from '../popup/store-popup/store-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CheckoutStoreComfirmationPopupComponent } from '../popup/checkout-store-comfirmation-popup/checkout-store-comfirmation-popup.component';
import { StoreConfirmationPopupComponent } from '../popup/store-confirmation-popup/store-confirmation-popup.component';
import {  timer } from 'rxjs';
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {

  cart: CartItem[];
  selectedVoucher: PromotionVoucher;
  selectedStore: Store;
  selectedDatetime: OrderPickupDatetime;
  selectedPaymentOption: { amount: string, option: string,point: number, type: string };
  remark: string;
  total: number = 0;
  subtotal: number = 0;
  discount: number = 0;
  gst: number = 0;
  user: User;
  tncCheck: Boolean = false;
  order: OrderToSubmit;
  isVoucher: boolean = false;

  isLoading: boolean = false;

  private unsubscribe$ = new Subject();

  intervalSubscription: any;

  timeInSeconds: number=0;

  differ: KeyValueDiffer<string, any>;

  minute: {
    left: string,
    right: string
  };
  seconds: {
    left: string,
    right: string
  };

  constructor(
    private cartService: CartService,
    private userService: UserService,
    private commonService: CommonService,
    public dialog: MatDialog,
    private menuService: MenuService,
    private differs: KeyValueDiffers,
    private snackBar: MatSnackBar,
    private router: Router
  ) { 
    this.commonService.updateTitle('Cart');
    this.differ = this.differs.find({}).create();
  }

  ngOnInit(): void {

    
    this.cartService.getCart();
  }

  private autoSelectVoucherMaxValue(order_array: any): void {
    if(this.cart.length <= 0) {
      this.userService.selectVoucher(null);
      return;
    }
    order_array = JSON.stringify(order_array);
    this.userService.getWalletVoucher('true', order_array).subscribe((response) => {
      if(!response || !response.success) {
        this.isVoucher = false;
        return;
      }
      if(!response.data || response.data == null) {
        this.isVoucher = false;
        return;
      }
      if(response.data.length > 0) {
        this.isVoucher = true;
      } else {
        this.isVoucher = false;
        return;
      }
      let vouchers = response.data.filter((voucher: PromotionVoucher) => voucher.is_match_order == 1 && voucher.valueType == 'value');
      if(vouchers.length <= 0) {
        return;
      }
      if(vouchers.length === 1) {
        this.userService.selectVoucher(vouchers[0]);
        this.openSnackBar(`The discount voucher '${vouchers[0].name}' has been applied to your order.`, 'Voucher Automatically Applied')
        return;
      }
      let maxValue: PromotionVoucher = null;
      vouchers.map((voucher: PromotionVoucher) => {
        if(!maxValue) {
          maxValue = voucher;
        } else {
          if(+voucher.value > +maxValue.value) {
            maxValue = voucher;
          }
        }
      });
      if(maxValue) {
        this.userService.selectVoucher(maxValue);
        this.openSnackBar(`The discount voucher '${maxValue.name}' has been applied to your order.`, 'Voucher Automatically Applied')
      }
    });
  }
  
  private openSnackBar(message: string, action: string): void {
    const snackBarRef = this.snackBar.open(message, action, {
      duration: 3000,
    });
    snackBarRef.onAction().subscribe(() => {
      this.selectVoucher();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.cartService.cart$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          return;
        }
        this.cart = data;
        if(this.cart.length <= 0) {
          this.userService.selectVoucher(null);
          return;
        }
      });
  
      this.userService.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          return;
        }
        this.user = data;
      })
  
      this.userService.selectedStore$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          return;
        }
        this.selectedStore = data;
        if(!this.userService.isVoucherSelected()) {
          const orderCart = this.cartService.getReadyCart();
          this.autoSelectVoucherMaxValue(orderCart);
        }
      });
  
      this.userService.selectedVoucher$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.selectedVoucher = data;
      });
    });
  }

  removeVoucher(): void {
    this.selectedPaymentOption = null;
    this.userService.removeVoucher();
  }

  selectVoucher(): void {
    const orderCart = this.cartService.getReadyCart();
    const dialogRef = this.dialog.open(VoucherPopupComponent, {
      width: '800px',
      data: {
        selectedVoucher: this.selectedVoucher,
        order_array: JSON.stringify(orderCart),
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((dataReturned) => {
      if(dataReturned) {
        this.userService.selectVoucher(dataReturned);
        this.selectedVoucher = dataReturned;
        this.selectedPaymentOption = null;
      }
    });
  }

  selectStore(): void {
    const dialogRef = this.dialog.open(StorePopupComponent, {
      width: '800px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((dataReturned) => {
      if(dataReturned && dataReturned.updated) {
        this.selectedDatetime = null;
      }
    });
  }

  onCheckoutConfirmStore(): void {
    const dialogRef = this.dialog.open(CheckoutStoreComfirmationPopupComponent, {
      width: '500px',
      disableClose: false
    });
    dialogRef.afterClosed().subscribe((dataReturned) => {
      if(!dataReturned) {
        //proceed checkout
        const order = this.getFinalOrder();

        if(+this.selectedPaymentOption.amount <= 0) {
          this.skipPaymentSubmitOrder(order);
        } else {
          this.dialog.open(PaymentComponent, {
            width: '800px',
            data: {
              order: order
            },
            disableClose: true
          });
        }
        
      }
    });
  }

  selectDateTime(): void {
    if(!this.selectedStore || (this.selectedStore && !this.selectedStore.id)) {
      this.commonService.alertDialog('Error!', 'Please select a store to view the date time available');
      return;
    }

    const orderCart = this.cartService.getReadyCart();
    const dialogRef = this.dialog.open(DatetimePopupComponent, {
      width: '400px',
      data: {
        payload: {
          session: this.user.session,
          deviceID: this.user.deviceID,
          order_array: JSON.stringify(orderCart),
          store_id: this.selectedStore.id
        },
        selectedDatetime: this.selectedDatetime
       },
       disableClose: true
    });
    dialogRef.afterClosed().subscribe((dataReturned) => {
      if(dataReturned && !dataReturned.pickupTime) {
        this.selectedDatetime = null;
        if(!this.user.session) {
          this.router.navigate(['/login']);
        }
        return;
      }
      if(this.timeInSeconds>0)
      {
        this.intervalSubscription.unsubscribe();
      }
      this.selectedDatetime = dataReturned;

      this.timeInSeconds=301;
      this.startTimer();
    });
  }

  startTimer() {
    this.intervalSubscription = timer(0, 1000)
    .subscribe(() => {
      if (this.timeInSeconds == 0) {
        
        this.intervalSubscription.unsubscribe();
        return;
      }

      this.timeInSeconds = this.timeInSeconds - 1;

      const minute = Math.floor(this.timeInSeconds / 60) % 60
      this.minute = {
        left: minute >= 10 ? minute.toString().charAt(0) : '0',
        right: minute >= 10 ? minute.toString().charAt(1) : Math.max(0, minute).toString(),
      }

      const seconds = this.timeInSeconds % 60;
      this.seconds = {
        left: seconds >= 10 ? seconds.toString().charAt(0) : '0',
        right: seconds >= 10 ? seconds.toString().charAt(1) : Math.max(0, seconds).toString(),
      }
    });
  }

  selectPaymentOption(): void {
    const dialogRef = this.dialog.open(PaymentOptionPopupComponent, {
      width: '400px',
      data: {
        user: {
          points: this.user.points,
          pointvalue: this.user.pointvalue,
          avatar: this.user.avatar,
        },
        total: this.total,
        selectedPaymentOption: this.selectedPaymentOption,
        store: this.selectedStore,
        cart: this.cart
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((dataReturned) => {
      this.selectedPaymentOption = dataReturned;
    });
  }

  ngDoCheck(): void {
    this.calculateSubTotal();
    this.calculateGst();
    this.calculateOverallTotal();
    const changes = this.differ.diff(this);
    if (changes) {
      changes.forEachChangedItem(item => {
        if(item.key === 'total' && item.previousValue && item.previousValue !== item.currentValue) {
          if(this.selectedPaymentOption) {
            this.selectedPaymentOption = null;
          }
          if(this.selectedDatetime) {
            this.selectedDatetime = null;
          }
        }
      });
    }
  }

  private calculateSubTotal(): void {
    this.subtotal = this.cartService.calculateTotalCart(this.cart);
  }

  private calculateGst(): void {
    this.gst = this.subtotal / 11;
  }

  private calculateOverallTotal(): void {
    if(this.selectedVoucher) {
      if(this.selectedVoucher.valueType === 'value') {
        this.discount = this.selectedVoucher.value;
      }
      if(this.selectedVoucher.valueType === 'percentage') {
        this.discount = this.subtotal * this.selectedVoucher.value / 100;
      }
      this.total = this.subtotal - this.discount;
    } else {
      this.total = this.subtotal;
    }
  }

  checkout(): void {

    if(!this.user.loggedin) {
      this.commonService.alertDialog('Error!', 'Please login to checkout the items in your cart.');
      return;
    }
    if(!this.tncCheck) {
      this.commonService.alertDialog('Error!', 'Please acknowledge the cross-contact statement before proceeding.');
      return;
    }
    if(this.timeInSeconds<1)
    {
      this.commonService.alertDialog('Alert!', 'Please re-select your pick up date and time. Your previous selection have exceeded the allowed permitted time.');
      this.selectedDatetime = null;
      return;
    }
    if(!this.selectedDatetime || (this.selectedDatetime && (!this.selectedDatetime.pickupDate || !this.selectedDatetime.pickupTime))) {
      this.commonService.alertDialog('Error!', 'Please select a pickup date and time.');
      return;
    }
    if(!this.selectedStore) {
      this.commonService.alertDialog('Error!', 'Please select a pickup store.');
      return;
    }
    if(!this.selectedPaymentOption) {
      this.commonService.alertDialog('Error!', 'Please select your payment option.');
      return;
    }
    //check store
    this.onCheckoutConfirmStore();

    // const order = this.getFinalOrder();

    // if(+this.selectedPaymentOption.amount <= 0) {
    //   this.skipPaymentSubmitOrder(order);
    // } else {
    //   this.dialog.open(PaymentComponent, {
    //     width: '800px',
    //     data: {
    //       order: order
    //     },
    //     disableClose: true
    //   });
    // }
  }

  private skipPaymentSubmitOrder(order): void {
    this.isLoading = true;
    order.payment_id = this.randomPaymentId();
    this.userService.submitOrder(order).subscribe((response) => {
      this.isLoading = false;
      if(!response.success) {
        this.commonService.alertDialog('Error!', response.message);
        return;
      }
      this.cartService.destroy();
      const orderedData = response.data;
      this.router.navigate(['dashboard/order/', orderedData.orderno]); //no checkout success page
      // this.userService.storePaymentOrder(orderedData); //checkout success page
      // this.router.navigate(['checkout/success/', orderedData.orderno]); //checkout success page
    });
  }

  private randomPaymentId(): string {
    return (+new Date).toString(36) + Math.random().toString(36).slice(-5);
  }

  getFinalOrder(): OrderToSubmit {
    const orderCart = this.cartService.getReadyCart();
    this.order = {
      payment_type: this.selectedPaymentOption.type,
      delivery_type: 'pickup',
      redeem_point: this.selectedPaymentOption.point,
      store_id: this.selectedStore.id,
      total_value: this.total,
      discount_value: this.discount,
      tax_value: Math.round(this.gst * 100) / 100,
      delivery_date: this.commonService.formatDate(new Date(this.selectedDatetime.pickupDate)),
      delivery_time: this.selectedDatetime.pickupTime,
      delivery_value: 0.00,
      phone: this.user.mobileno,
      order_array: JSON.stringify(orderCart),
    }
    this.order.voucher_id = this.selectedVoucher ? this.selectedVoucher.code : '';
    this.order.remark = this.remark ? this.remark : '';

    return this.order;
  }

  clearCart(): void {
    if(this.selectedVoucher) {
      let dialogResponse = this.commonService.actionDialog('Alert', 'Changing quantity or removing a product will require you to apply a voucher again.', 'Proceed');
      dialogResponse.subscribe(data => {
        if(data) {
          this.removeVoucher();
          this.cartService.destroy();
        }
      });
      return;
    }
    this.cartService.destroy();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
