<!-- <button class="close" mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button> -->
  
  <div mat-dialog-content class="pt-4" *ngIf="selectedStore">
    <div class="text-center">
      <p class="font-weight-bold f18 mb-2">Confirmed Store: </p>
      <p class="font-weight-bold f24 mb-0">{{selectedStore.name}}</p>
      <p class="font-weight-bold f14" *ngIf="locationpermission">({{selectedStore.distance}} away)</p>
      <p class="mt-3">This is your chosen store. To change it, 
        click Change Store <br/>(note: your cart will be cleared if you switch).<br/> Otherwise, click CONFIRM to proceed.</p>
    </div>
  </div>
  
  <div mat-dialog-actions align="center" *ngIf="selectedStore">
    <button mat-raised-button mat-dialog-close="true" (click)="selectStore()">Change Store</button>
    <button mat-raised-button color="primary" mat-dialog-close>CONFIRM</button>
  </div>
  
  <div mat-dialog-content class="pt-4" *ngIf="!selectedStore">
    <div class="text-center">
      <p class="font-weight-bold f18 mb-2">Please select a store</p>
    </div>
  </div>
  
  <div mat-dialog-actions align="center" *ngIf="!selectedStore">
    <button mat-raised-button mat-dialog-close="true">Select a Store</button>
  </div>