<div class="page-content">
  <section class="checkout-detail">
    <div class="container-fluid">
      <div class="row mb-40">
        <div class="col-12 mb-4">
          <a class="text-underline primary-text" [routerLink]="['/product']"> << Back to Product Selection</a>
        </div>

        <div class="col-md-6">
          <div class="section-title d-flex flex-wrap justify-content-between align-items-center">
            <div class="title">Checkout</div>
            <button class="btn d-flex align-items-center" (click)="clearCart()" [disabled]="isLoading"><mat-icon class="mr-1">delete</mat-icon>Deselect all</button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <app-cart></app-cart>
        </div>

        <div class="col-md-6">
          <div class="detail-content">
            <div class="d-flex flex-wrap align-items-center justify-content-between">
              <div>Subtotal</div>
              <div class="mx-3">{{subtotal | currency}}</div>
            </div>

            <div class="border-bottom mb-2 pb-2">
              <div class="d-flex flex-wrap align-items-center justify-content-between">
                <div>Voucher Code</div>
                <button mat-button (click)="selectVoucher()" [disabled]="isLoading">
                  <mat-icon class="blinking" *ngIf="isVoucher">bookmark</mat-icon>
                  <span *ngIf="!selectedVoucher">Select Voucher</span>
                  <span *ngIf="selectedVoucher">{{selectedVoucher.code}}</span>
                </button>
              </div>
              <div class="d-flex flex-wrap align-items-center justify-content-between" *ngIf="selectedVoucher">
                <div>
                  {{selectedVoucher.name}} <span *ngIf="selectedVoucher.valueType === 'percentage'">({{selectedVoucher.value | number}}%)</span>
                  <a class="f12 ml-2 cursor-pointer" role="button" (click)="removeVoucher()">(Remove)</a>
                </div>
                <div class="mx-3">
                  <span>-{{discount | currency}}</span>
                </div>
              </div>
            </div>

            <div class="d-flex flex-wrap align-items-center justify-content-between font-weight-bold f18">
              <div>Total</div>
              <div class="mx-3">{{total | currency}}</div>
            </div>

            <div class="d-flex flex-wrap align-items-center justify-content-between mb-4 f14">
              <div>GST Incl</div>
              <div class="mx-3">{{gst | currency}}</div>
            </div>

            <div class="d-flex flex-wrap align-items-center justify-content-between detail-selection">
              <div class="font-weight-bold">Selected Store</div>
              <button mat-button (click)="selectStore()" [disabled]="isLoading">
                <span *ngIf="!selectedStore">Select Store</span>
                <span *ngIf="selectedStore">{{selectedStore.name}}</span>
              </button>
            </div>

            <div class="d-flex flex-wrap align-items-center justify-content-between detail-selection">
              <div class="font-weight-bold">Pickup date and time <br><small *ngIf="timeInSeconds>0"> Please checkout within the given time (<b>{{minute?.left ? minute.left : '0'}}{{minute?.right ? minute.right : '0'}} : {{seconds?.left ? seconds.left : '0'}}{{seconds?.right ? seconds.right : '0'}}</b>)</small></div>
              <button mat-button (click)="selectDateTime()" [disabled]="isLoading">
                <span *ngIf="!selectedDatetime">Select datetime</span>
                <span *ngIf="selectedDatetime">{{selectedDatetime.pickupDate | date:'d MMM, Y'}} {{selectedDatetime.pickupTime}}</span>
              </button>
            </div>

            <div class="d-flex flex-wrap align-items-center justify-content-between detail-selection">
              <div class="font-weight-bold">Payment Options</div>
              <button mat-button (click)="selectPaymentOption()" [disabled]="isLoading">
                <span *ngIf="!selectedPaymentOption">Select payment option</span>
                <span *ngIf="selectedPaymentOption">{{selectedPaymentOption.option}} ({{selectedPaymentOption.amount | currency}})</span>
              </button>
            </div>

            <div class="mt-2 mb-4">
              <div class="font-weight-bold">Order Notes</div>
              <textarea [(ngModel)]="remark" class="w-100 remark-textarea" cols="40" rows="4"></textarea>
            </div>
          </div>

          <div class="form-check mb-4 px-0">
            <mat-checkbox class="example-margin checkout-checkbox" [(ngModel)]="tncCheck" [ngModelOptions]="{standalone: true}">
              <div class="text-wrap">
                All products are prepared in the same environment as ingredients that contains allergens. While we take great care to cater for individual dietary requirements, cross-contamination may occur during preparation
              </div>
            </mat-checkbox>
          </div>

          <div class="text-center">
            <button class="btn btn-black-round" [disabled]="isLoading" (click)="checkout()">Checkout</button>
            <!-- <button class="btn btn-black-round" [disabled]="isLoading" (click)="onCheckoutConfirmStore()">Checkout</button> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</div>